@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body .page-content p,
body .page-content,
body p,
body {
    color: #000;
    font-family: 'Roboto', sans-serif;
}

.post-grid .layer-wrapper:before {
    padding: 0;
}

.pt-cv-view a img {
    width: 100%;
}

#wpadminbar ul li#wp-admin-bar-colibri_top_bar_menu {
    background-color: rgba(3, 169, 244, 0.3);
    padding-left: 8px;
    padding-right: 8px;
    margin: 0px 16px;
}

#wpadminbar ul li#wp-admin-bar-colibri_top_bar_menu>a {
    background-color: transparent;
    color: #fff;
}


#wpadminbar ul li#wp-admin-bar-colibri_top_bar_menu>a img {
    max-height: 24px;
    margin-top: -4px;
    margin-right: 6px;
}

#wpadminbar ul li#wp-admin-bar-colibri_top_bar_menu>.ab-sub-wrapper {
    margin-left: -8px;
}

body {
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--color--colibri-color-1: #303e7a;
    --wp--preset--color--colibri-color-2: #f7e597;
    --wp--preset--color--colibri-color-3: #f66161;
    --wp--preset--color--colibri-color-4: #9ec9e2;
    --wp--preset--color--colibri-color-5: #f6f8fa;
    --wp--preset--color--colibri-color-6: #202a54;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}

:where(.is-layout-flex) {
    gap: 0.5em;
}

:where(.is-layout-grid) {
    gap: 0.5em;
}

body .is-layout-flow>.alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-flow>.alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-flow>.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained>.alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-constrained>.alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-constrained>.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained> :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    max-width: var(--wp--style--global--content-size);
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained>.alignwide {
    max-width: var(--wp--style--global--wide-size);
}

body .is-layout-flex {
    display: flex;
}

body .is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
}

body .is-layout-flex>* {
    margin: 0;
}

body .is-layout-grid {
    display: grid;
}

body .is-layout-grid>* {
    margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

:where(.wp-block-columns.is-layout-grid) {
    gap: 2em;
}

:where(.wp-block-post-template.is-layout-flex) {
    gap: 1.25em;
}

:where(.wp-block-post-template.is-layout-grid) {
    gap: 1.25em;
}

.has-black-color {
    color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
    color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
    color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
    color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
    color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
    color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
    color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
    color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
    color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
    color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
    color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
    color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
    background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
    background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
    background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
    background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
    background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
    background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
    background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
    background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
    background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
    background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
    border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
    border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
    border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
    border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
    border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
    border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
    border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
    border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
    border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
    border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
    background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
    background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
    background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
    background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
    background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
    background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
    background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
    background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
    background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
    background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
    font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
    font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
    font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
    font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
    color: inherit;
}

:where(.wp-block-post-template.is-layout-flex) {
    gap: 1.25em;
}

:where(.wp-block-post-template.is-layout-grid) {
    gap: 1.25em;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

:where(.wp-block-columns.is-layout-grid) {
    gap: 2em;
}

.wp-block-pullquote {
    font-size: 1.5em;
    line-height: 1.6;
}


/* page css */
/* part css : theme-shapes */
.colibri-shape-circles {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/circles.png')
}

.colibri-shape-10degree-stripes {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/10degree-stripes.png')
}

.colibri-shape-rounded-squares-blue {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/rounded-squares-blue.png')
}

.colibri-shape-many-rounded-squares-blue {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/many-rounded-squares-blue.png')
}

.colibri-shape-two-circles {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/two-circles.png')
}

.colibri-shape-circles-2 {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/circles-2.png')
}

.colibri-shape-circles-3 {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/circles-3.png')
}

.colibri-shape-circles-gradient {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/circles-gradient.png')
}

.colibri-shape-circles-white-gradient {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/circles-white-gradient.png')
}

.colibri-shape-waves {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/waves.png')
}

.colibri-shape-waves-inverted {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/waves-inverted.png')
}

.colibri-shape-dots {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/dots.png')
}

.colibri-shape-left-tilted-lines {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/left-tilted-lines.png')
}

.colibri-shape-right-tilted-lines {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/right-tilted-lines.png')
}

.colibri-shape-right-tilted-strips {
    background-image: url('https://jiliko.io/wp-content/themes/colibri-wp/resources/images/header-shapes/right-tilted-strips.png')
}

/* part css : theme */

.h-y-container>*:not(:last-child),
.h-x-container-inner>* {
    margin-bottom: 20px;
}

.h-x-container-inner,
.h-column__content>.h-x-container>*:last-child {
    margin-bottom: -20px;
}

.h-x-container-inner>* {
    padding-left: 10px;
    padding-right: 10px;
}

.h-x-container-inner {
    margin-left: -10px;
    margin-right: -10px;
}

[class*=style-],
[class*=local-style-],
.h-global-transition,
.h-global-transition-all,
.h-global-transition-all * {
    transition-duration: 1s;
}

.wp-block-button .wp-block-button__link:not(.has-background),
.wp-block-file .wp-block-file__button {
    background-color: #303e7a;
    background-image: none;
}

.wp-block-button .wp-block-button__link:not(.has-background):hover,
.wp-block-button .wp-block-button__link:not(.has-background):focus,
.wp-block-button .wp-block-button__link:not(.has-background):active,
.wp-block-file .wp-block-file__button:hover,
.wp-block-file .wp-block-file__button:focus,
.wp-block-file .wp-block-file__button:active {
    background-color: rgb(7, 29, 122);
    background-image: none;
}

.wp-block-button.is-style-outline .wp-block-button__link:not(.has-background) {
    color: #303e7a;
    background-color: transparent;
    background-image: none;
    border-top-width: 2px;
    border-top-color: #303e7a;
    border-top-style: solid;
    border-right-width: 2px;
    border-right-color: #303e7a;
    border-right-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #303e7a;
    border-bottom-style: solid;
    border-left-width: 2px;
    border-left-color: #303e7a;
    border-left-style: solid;
}

.wp-block-button.is-style-outline .wp-block-button__link:not(.has-background):hover,
.wp-block-button.is-style-outline .wp-block-button__link:not(.has-background):focus,
.wp-block-button.is-style-outline .wp-block-button__link:not(.has-background):active {
    color: #fff;
    background-color: #303e7a;
    background-image: none;
}

.has-background-color,
*[class^="wp-block-"].is-style-solid-color {
    background-color: #303e7a;
    background-image: none;
}

.has-colibri-color-1-background-color {
    background-color: #303e7a;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-1-background-color {
    background-color: #303e7a;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-1-background-color:hover,
.wp-block-button .wp-block-button__link.has-colibri-color-1-background-color:focus,
.wp-block-button .wp-block-button__link.has-colibri-color-1-background-color:active {
    background-color: rgb(7, 29, 122);
    background-image: none;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-1-background-color {
    color: #303e7a;
    background-color: transparent;
    background-image: none;
    border-top-width: 2px;
    border-top-color: #303e7a;
    border-top-style: solid;
    border-right-width: 2px;
    border-right-color: #303e7a;
    border-right-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #303e7a;
    border-bottom-style: solid;
    border-left-width: 2px;
    border-left-color: #303e7a;
    border-left-style: solid;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-1-background-color:hover,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-1-background-color:focus,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-1-background-color:active {
    color: #fff;
    background-color: #303e7a;
    background-image: none;
}

*[class^="wp-block-"].has-colibri-color-1-background-color,
*[class^="wp-block-"] .has-colibri-color-1-background-color,
*[class^="wp-block-"].is-style-solid-color.has-colibri-color-1-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-1-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-1-color p {
    background-color: #303e7a;
    background-image: none;
}

.has-colibri-color-1-color {
    color: #303e7a;
}

.has-colibri-color-2-background-color {
    background-color: #f7e597;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-2-background-color {
    background-color: #f7e597;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-2-background-color:hover,
.wp-block-button .wp-block-button__link.has-colibri-color-2-background-color:focus,
.wp-block-button .wp-block-button__link.has-colibri-color-2-background-color:active {
    background-color: rgb(162, 150, 99);
    background-image: none;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-2-background-color {
    color: #f7e597;
    background-color: transparent;
    background-image: none;
    border-top-width: 2px;
    border-top-color: #f7e597;
    border-top-style: solid;
    border-right-width: 2px;
    border-right-color: #f7e597;
    border-right-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #f7e597;
    border-bottom-style: solid;
    border-left-width: 2px;
    border-left-color: #f7e597;
    border-left-style: solid;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-2-background-color:hover,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-2-background-color:focus,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-2-background-color:active {
    color: #fff;
    background-color: #f7e597;
    background-image: none;
}

*[class^="wp-block-"].has-colibri-color-2-background-color,
*[class^="wp-block-"] .has-colibri-color-2-background-color,
*[class^="wp-block-"].is-style-solid-color.has-colibri-color-2-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-2-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-2-color p {
    background-color: #f7e597;
    background-image: none;
}

.has-colibri-color-2-color {
    color: #f7e597;
}

.has-colibri-color-3-background-color {
    background-color: #f66161;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-3-background-color {
    background-color: #f66161;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-3-background-color:hover,
.wp-block-button .wp-block-button__link.has-colibri-color-3-background-color:focus,
.wp-block-button .wp-block-button__link.has-colibri-color-3-background-color:active {
    background-color: rgb(161, 63, 63);
    background-image: none;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-3-background-color {
    color: #f66161;
    background-color: transparent;
    background-image: none;
    border-top-width: 2px;
    border-top-color: #f66161;
    border-top-style: solid;
    border-right-width: 2px;
    border-right-color: #f66161;
    border-right-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #f66161;
    border-bottom-style: solid;
    border-left-width: 2px;
    border-left-color: #f66161;
    border-left-style: solid;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-3-background-color:hover,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-3-background-color:focus,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-3-background-color:active {
    color: #fff;
    background-color: #f66161;
    background-image: none;
}

*[class^="wp-block-"].has-colibri-color-3-background-color,
*[class^="wp-block-"] .has-colibri-color-3-background-color,
*[class^="wp-block-"].is-style-solid-color.has-colibri-color-3-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-3-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-3-color p {
    background-color: #f66161;
    background-image: none;
}

.has-colibri-color-3-color {
    color: #f66161;
}

.has-colibri-color-4-background-color {
    background-color: #9ec9e2;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-4-background-color {
    background-color: #9ec9e2;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-4-background-color:hover,
.wp-block-button .wp-block-button__link.has-colibri-color-4-background-color:focus,
.wp-block-button .wp-block-button__link.has-colibri-color-4-background-color:active {
    background-color: rgb(7, 146, 226);
    background-image: none;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-4-background-color {
    color: #9ec9e2;
    background-color: transparent;
    background-image: none;
    border-top-width: 2px;
    border-top-color: #9ec9e2;
    border-top-style: solid;
    border-right-width: 2px;
    border-right-color: #9ec9e2;
    border-right-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #9ec9e2;
    border-bottom-style: solid;
    border-left-width: 2px;
    border-left-color: #9ec9e2;
    border-left-style: solid;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-4-background-color:hover,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-4-background-color:focus,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-4-background-color:active {
    color: #fff;
    background-color: #9ec9e2;
    background-image: none;
}

*[class^="wp-block-"].has-colibri-color-4-background-color,
*[class^="wp-block-"] .has-colibri-color-4-background-color,
*[class^="wp-block-"].is-style-solid-color.has-colibri-color-4-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-4-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-4-color p {
    background-color: #9ec9e2;
    background-image: none;
}

.has-colibri-color-4-color {
    color: #9ec9e2;
}

.has-colibri-color-5-background-color {
    background-color: #f6f8fa;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-5-background-color {
    background-color: #f6f8fa;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-5-background-color:hover,
.wp-block-button .wp-block-button__link.has-colibri-color-5-background-color:focus,
.wp-block-button .wp-block-button__link.has-colibri-color-5-background-color:active {
    background-color: rgb(162, 164, 165);
    background-image: none;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-5-background-color {
    color: #f6f8fa;
    background-color: transparent;
    background-image: none;
    border-top-width: 2px;
    border-top-color: #f6f8fa;
    border-top-style: solid;
    border-right-width: 2px;
    border-right-color: #f6f8fa;
    border-right-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #f6f8fa;
    border-bottom-style: solid;
    border-left-width: 2px;
    border-left-color: #f6f8fa;
    border-left-style: solid;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-5-background-color:hover,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-5-background-color:focus,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-5-background-color:active {
    color: #fff;
    background-color: #f6f8fa;
    background-image: none;
}

*[class^="wp-block-"].has-colibri-color-5-background-color,
*[class^="wp-block-"] .has-colibri-color-5-background-color,
*[class^="wp-block-"].is-style-solid-color.has-colibri-color-5-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-5-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-5-color p {
    background-color: #f6f8fa;
    background-image: none;
}

.has-colibri-color-5-color {
    color: #f6f8fa;
}

.has-colibri-color-6-background-color {
    background-color: #202a54;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-6-background-color {
    background-color: #202a54;
    background-image: none;
}

.wp-block-button .wp-block-button__link.has-colibri-color-6-background-color:hover,
.wp-block-button .wp-block-button__link.has-colibri-color-6-background-color:focus,
.wp-block-button .wp-block-button__link.has-colibri-color-6-background-color:active {
    background-color: rgb(32, 42, 84);
    background-image: none;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-6-background-color {
    color: #202a54;
    background-color: transparent;
    background-image: none;
    border-top-width: 2px;
    border-top-color: #202a54;
    border-top-style: solid;
    border-right-width: 2px;
    border-right-color: #202a54;
    border-right-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #202a54;
    border-bottom-style: solid;
    border-left-width: 2px;
    border-left-color: #202a54;
    border-left-style: solid;
}

.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-6-background-color:hover,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-6-background-color:focus,
.wp-block-button.is-style-outline .wp-block-button__link.has-colibri-color-6-background-color:active {
    color: #fff;
    background-color: #202a54;
    background-image: none;
}

*[class^="wp-block-"].has-colibri-color-6-background-color,
*[class^="wp-block-"] .has-colibri-color-6-background-color,
*[class^="wp-block-"].is-style-solid-color.has-colibri-color-6-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-6-color,
*[class^="wp-block-"].is-style-solid-color blockquote.has-colibri-color-6-color p {
    background-color: #202a54;
    background-image: none;
}

.has-colibri-color-6-color {
    color: #202a54;
}

#colibri .woocommerce-store-notice,
#colibri.woocommerce .content .h-section input[type=submit],
#colibri.woocommerce-page .content .h-section input[type=button],
#colibri.woocommerce .content .h-section input[type=button],
#colibri.woocommerce-page .content .h-section .button,
#colibri.woocommerce .content .h-section .button,
#colibri.woocommerce-page .content .h-section a.button,
#colibri.woocommerce .content .h-section a.button,
#colibri.woocommerce-page .content .h-section button.button,
#colibri.woocommerce .content .h-section button.button,
#colibri.woocommerce-page .content .h-section input.button,
#colibri.woocommerce .content .h-section input.button,
#colibri.woocommerce-page .content .h-section input#submit,
#colibri.woocommerce .content .h-section input#submit,
#colibri.woocommerce-page .content .h-section a.added_to_cart,
#colibri.woocommerce .content .h-section a.added_to_cart,
#colibri.woocommerce-page .content .h-section .ui-slider-range,
#colibri.woocommerce .content .h-section .ui-slider-range,
#colibri.woocommerce-page .content .h-section .ui-slider-handle,
#colibri.woocommerce .content .h-section .ui-slider-handle {
    background-color: #303e7a;
    background-image: none;
    border-top-width: 0px;
    border-top-color: #303e7a;
    border-top-style: solid;
    border-right-width: 0px;
    border-right-color: #303e7a;
    border-right-style: solid;
    border-bottom-width: 0px;
    border-bottom-color: #303e7a;
    border-bottom-style: solid;
    border-left-width: 0px;
    border-left-color: #303e7a;
    border-left-style: solid;
}

#colibri .woocommerce-store-notice:hover,
#colibri .woocommerce-store-notice:focus,
#colibri .woocommerce-store-notice:active,
#colibri.woocommerce .content .h-section input[type=submit]:hover,
#colibri.woocommerce .content .h-section input[type=submit]:focus,
#colibri.woocommerce .content .h-section input[type=submit]:active,
#colibri.woocommerce-page .content .h-section input[type=button]:hover,
#colibri.woocommerce-page .content .h-section input[type=button]:focus,
#colibri.woocommerce-page .content .h-section input[type=button]:active,
#colibri.woocommerce .content .h-section input[type=button]:hover,
#colibri.woocommerce .content .h-section input[type=button]:focus,
#colibri.woocommerce .content .h-section input[type=button]:active,
#colibri.woocommerce-page .content .h-section .button:hover,
#colibri.woocommerce-page .content .h-section .button:focus,
#colibri.woocommerce-page .content .h-section .button:active,
#colibri.woocommerce .content .h-section .button:hover,
#colibri.woocommerce .content .h-section .button:focus,
#colibri.woocommerce .content .h-section .button:active,
#colibri.woocommerce-page .content .h-section a.button:hover,
#colibri.woocommerce-page .content .h-section a.button:focus,
#colibri.woocommerce-page .content .h-section a.button:active,
#colibri.woocommerce .content .h-section a.button:hover,
#colibri.woocommerce .content .h-section a.button:focus,
#colibri.woocommerce .content .h-section a.button:active,
#colibri.woocommerce-page .content .h-section button.button:hover,
#colibri.woocommerce-page .content .h-section button.button:focus,
#colibri.woocommerce-page .content .h-section button.button:active,
#colibri.woocommerce .content .h-section button.button:hover,
#colibri.woocommerce .content .h-section button.button:focus,
#colibri.woocommerce .content .h-section button.button:active,
#colibri.woocommerce-page .content .h-section input.button:hover,
#colibri.woocommerce-page .content .h-section input.button:focus,
#colibri.woocommerce-page .content .h-section input.button:active,
#colibri.woocommerce .content .h-section input.button:hover,
#colibri.woocommerce .content .h-section input.button:focus,
#colibri.woocommerce .content .h-section input.button:active,
#colibri.woocommerce-page .content .h-section input#submit:hover,
#colibri.woocommerce-page .content .h-section input#submit:focus,
#colibri.woocommerce-page .content .h-section input#submit:active,
#colibri.woocommerce .content .h-section input#submit:hover,
#colibri.woocommerce .content .h-section input#submit:focus,
#colibri.woocommerce .content .h-section input#submit:active,
#colibri.woocommerce-page .content .h-section a.added_to_cart:hover,
#colibri.woocommerce-page .content .h-section a.added_to_cart:focus,
#colibri.woocommerce-page .content .h-section a.added_to_cart:active,
#colibri.woocommerce .content .h-section a.added_to_cart:hover,
#colibri.woocommerce .content .h-section a.added_to_cart:focus,
#colibri.woocommerce .content .h-section a.added_to_cart:active,
#colibri.woocommerce-page .content .h-section .ui-slider-range:hover,
#colibri.woocommerce-page .content .h-section .ui-slider-range:focus,
#colibri.woocommerce-page .content .h-section .ui-slider-range:active,
#colibri.woocommerce .content .h-section .ui-slider-range:hover,
#colibri.woocommerce .content .h-section .ui-slider-range:focus,
#colibri.woocommerce .content .h-section .ui-slider-range:active,
#colibri.woocommerce-page .content .h-section .ui-slider-handle:hover,
#colibri.woocommerce-page .content .h-section .ui-slider-handle:focus,
#colibri.woocommerce-page .content .h-section .ui-slider-handle:active,
#colibri.woocommerce .content .h-section .ui-slider-handle:hover,
#colibri.woocommerce .content .h-section .ui-slider-handle:focus,
#colibri.woocommerce .content .h-section .ui-slider-handle:active {
    background-color: rgb(7, 29, 122);
    background-image: none;
    border-top-width: 0px;
    border-top-color: rgb(7, 29, 122);
    border-top-style: solid;
    border-right-width: 0px;
    border-right-color: rgb(7, 29, 122);
    border-right-style: solid;
    border-bottom-width: 0px;
    border-bottom-color: rgb(7, 29, 122);
    border-bottom-style: solid;
    border-left-width: 0px;
    border-left-color: rgb(7, 29, 122);
    border-left-style: solid;
}

#colibri.woocommerce-page .content .h-section .star-rating::before,
#colibri.woocommerce .content .h-section .star-rating::before,
#colibri.woocommerce-page .content .h-section .star-rating span::before,
#colibri.woocommerce .content .h-section .star-rating span::before {
    color: #303e7a;
}

#colibri.woocommerce-page .content .h-section .price,
#colibri.woocommerce .content .h-section .price {
    color: #303e7a;
}

#colibri.woocommerce-page .content .h-section .price del,
#colibri.woocommerce .content .h-section .price del {
    color: rgb(89, 95, 122);
}

#colibri.woocommerce-page .content .h-section .onsale,
#colibri.woocommerce .content .h-section .onsale {
    background-color: #303e7a;
    background-image: none;
}

#colibri.woocommerce-page .content .h-section .onsale:hover,
#colibri.woocommerce-page .content .h-section .onsale:focus,
#colibri.woocommerce-page .content .h-section .onsale:active,
#colibri.woocommerce .content .h-section .onsale:hover,
#colibri.woocommerce .content .h-section .onsale:focus,
#colibri.woocommerce .content .h-section .onsale:active {
    background-color: rgb(7, 29, 122);
    background-image: none;
}

#colibri.woocommerce ul.products li.product h2:hover {
    color: #303e7a;
}

#colibri.woocommerce-page .content .h-section .woocommerce-pagination .page-numbers.current,
#colibri.woocommerce .content .h-section .woocommerce-pagination .page-numbers.current,
#colibri.woocommerce-page .content .h-section .woocommerce-pagination a.page-numbers:hover,
#colibri.woocommerce .content .h-section .woocommerce-pagination a.page-numbers:hover {
    background-color: #303e7a;
    background-image: none;
}

#colibri.woocommerce-page .content .h-section .comment-form-rating .stars a,
#colibri.woocommerce .content .h-section .comment-form-rating .stars a {
    color: #303e7a;
}

.h-section-global-spacing {
    padding-top: 90px;
    padding-bottom: 90px;
}

#colibri .colibri-language-switcher {
    background-color: white;
    background-image: none;
    top: 80px;
    border-top-width: 0px;
    border-top-style: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 0px;
    border-right-width: 0px;
    border-right-style: none;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0px;
    border-left-width: 0px;
    border-left-style: none;
}

#colibri .colibri-language-switcher .lang-item {
    padding-top: 14px;
    padding-right: 18px;
    padding-bottom: 14px;
    padding-left: 18px;
}

body {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    color: #f66161;
}

body a {
    font-family: Open Sans;
    font-weight: 400;
    text-decoration: none;
    font-size: 1em;
    line-height: 1.5;
    color: #03a9f4;
}

body p {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    color: #f66161;
}

body .h-lead p {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 300;
    font-size: 1.25em;
    line-height: 1.5;
    color: rgb(60, 65, 84);
}

body blockquote p {
    margin-bottom: 16px;
    font-family: Open Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    color: rgb(64, 85, 169);
}

body h1 {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 900;
    font-size: 3.375em;
    line-height: 1.26;
    color: rgb(23, 37, 42);
    text-transform: uppercase;
}

body h2 {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 700;
    font-size: 2.5em;
    line-height: 1.143;
    color: #f66161;
    text-transform: uppercase;
}

body h3 {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 700;
    font-size: 2.25em;
    line-height: 1.25;
    color: #f66161;
    text-transform: uppercase;
}

body h4 {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.25em;
    line-height: 1.6;
    color: #f66161;
}

body h5 {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 700;
    font-size: 1.125em;
    line-height: 1.55;
    color: #f7e597;
    text-transform: uppercase;
}

body h6 {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.6;
    color: #f66161;
}

.content.colibri-main-content-single .main-section .colibri-sidebar.blog-sidebar-right,
.content.colibri-main-content-archive .main-section .colibri-sidebar.blog-sidebar-right {
    width: 25%;
    flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    max-width: 25%;
}


@media (min-width: 768px) and (max-width: 1023px) {
    .h-section-global-spacing {
        padding-top: 60px;
        padding-bottom: 60px;
    }

}

@media (max-width: 767px) {
    .h-section-global-spacing {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    body h1 {
        font-size: 2.8em;
    }

}

/* part css : page */
#colibri .style-860 {
    height: auto;
    min-height: unset;
}

#colibri .style-862 {
    text-align: center;
    height: auto;
    min-height: unset;
}

#colibri .style-local-19-c4-outer {
    width: 100%;
    flex: 0 0 auto;
    -ms-flex: 0 0 auto;
}

#colibri .style-local-19-c4 {
    text-align: left;
}

#colibri .style-282 {
    height: auto;
    min-height: unset;
    color: #f6f8fa;
    background-position: center center;
    background-size: cover;
    background-image: url("https://jiliko.io/wp-content/uploads/2023/02/JokerSuperReels.jpg");
    background-attachment: scroll;
    background-repeat: no-repeat;
    padding-top: 90px;
    padding-bottom: 90px;
}

#colibri .style-282 h1 {
    color: #f6f8fa;
}

#colibri .style-282 h2 {
    color: #f6f8fa;
}

#colibri .style-282 h3 {
    color: #f6f8fa;
}

#colibri .style-282 h4 {
    color: #f6f8fa;
}

#colibri .style-282 h5 {
    color: #f6f8fa;
}

#colibri .style-282 h6 {
    color: #f6f8fa;
}

#colibri .style-282 p {
    color: #f6f8fa;
}

#colibri .style-296 {
    text-align: center;
    height: auto;
    min-height: unset;
}

#colibri .style-571 {
    animation-duration: 0.5s;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f6f8fa;
    background-image: none;
}

#colibri .h-navigation_sticky .style-571,
#colibri .h-navigation_sticky.style-571 {
    background-color: #ffffff;
    background-image: none;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}

#colibri .style-573 {
    text-align: center;
    height: auto;
    min-height: unset;
}

#colibri .style-574-image {
    max-height: 70px;
}

#colibri .style-574 a,
#colibri .style-574 .logo-text {
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1em;
}

#colibri .style-574 .logo-text {
    color: #f66161;
}

#colibri .h-navigation_sticky .style-574-image,
#colibri .h-navigation_sticky.style-574-image {
    max-height: 70px;
}

#colibri .h-navigation_sticky .style-574 a,
#colibri .h-navigation_sticky .style-574 .logo-text,
#colibri .h-navigation_sticky.style-574 a,
#colibri .h-navigation_sticky.style-574 .logo-text {
    color: #000000;
    text-decoration: none;
}

#colibri .h-navigation_sticky .style-574 .logo-text,
#colibri .h-navigation_sticky.style-574 .logo-text {
    color: #f66161;
}

#colibri .style-576 {
    text-align: center;
    height: auto;
    min-height: unset;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu {
    justify-content: center;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li {
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 20px;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item:hover {
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 20px;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li>a {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    color: black;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li.hover>a {
    color: #303e7a;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li:hover>a,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li.hover>a {
    color: #303e7a;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item>a,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item>a:hover {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    color: #303e7a;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul {
    background-color: #ffffff;
    background-image: none;
    margin-right: 5px;
    margin-left: 5px;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul li {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-top-width: 0px;
    border-top-style: none;
    border-right-width: 0px;
    border-right-style: none;
    border-bottom-width: 1px;
    border-bottom-color: rgba(128, 128, 128, .2);
    border-bottom-style: solid;
    border-left-width: 0px;
    border-left-style: none;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul>li.hover {
    background-color: #F8F8F8;
    background-image: none;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul>li:hover,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul>li.hover {
    background-color: #F8F8F8;
    background-image: none;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul li.current_page_item,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul>li.current_page_item:hover {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-top-width: 0px;
    border-top-style: none;
    border-right-width: 0px;
    border-right-style: none;
    border-bottom-width: 1px;
    border-bottom-color: rgba(128, 128, 128, .2);
    border-bottom-style: solid;
    border-left-width: 0px;
    border-left-style: none;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul>li>a {
    font-size: 14px;
    color: #6B7C93;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul>li.current_page_item>a,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul>li.current_page_item>a:hover {
    font-size: 14px;
    color: #6B7C93;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu.bordered-active-item>li::after,
#colibri .style-577 ul.colibri-menu.bordered-active-item>li::before {
    background-color: #303e7a;
    background-image: none;
    height: 3px;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu.solid-active-item>li::after,
#colibri .style-577 ul.colibri-menu.solid-active-item>li::before {
    background-color: white;
    background-image: none;
    border-top-width: 0px;
    border-top-style: none;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    border-right-width: 0px;
    border-right-style: none;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    border-left-width: 0px;
    border-left-style: none;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li>ul {
    margin-top: 0px;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li>ul::before {
    height: 0px;
    width: 100%;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>a>svg,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>a>.arrow-wrapper {
    padding-right: 5px;
    padding-left: 5px;
    color: black;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li.current_page_item>a>svg,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item:hover>a>svg,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li.current_page_item>a>.arrow-wrapper,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item:hover>a>.arrow-wrapper {
    padding-right: 5px;
    padding-left: 5px;
    color: black;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul li>a>svg,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul li>a>.arrow-wrapper {
    color: black;
}

#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul li.current_page_item>a>svg,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul>li.current_page_item:hover>a>svg,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul li.current_page_item>a>.arrow-wrapper,
#colibri .style-577>div>.colibri-menu-container>ul.colibri-menu li>ul>li.current_page_item:hover>a>.arrow-wrapper {
    color: black;
}

#colibri .h-navigation_sticky .style-577>div>.colibri-menu-container>ul.colibri-menu,
#colibri .h-navigation_sticky.style-577>div>.colibri-menu-container>ul.colibri-menu {
    justify-content: flex-end;
}

#colibri .style-578-offscreen {
    background-color: #222B34;
    background-image: none;
    width: 300px !important;
}

#colibri .style-578-offscreenOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    background-image: none;
}

#colibri .style-578 .h-hamburger-icon {
    background-color: rgba(0, 0, 0, 0.1);
    background-image: none;
    border-top-width: 0px;
    border-top-color: black;
    border-top-style: solid;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    border-right-width: 0px;
    border-right-color: black;
    border-right-style: solid;
    border-bottom-width: 0px;
    border-bottom-color: black;
    border-bottom-style: solid;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    border-left-width: 0px;
    border-left-color: black;
    border-left-style: solid;
    fill: white;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    width: 24px;
    height: 24px;
}

#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li>a {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    border-top-width: 0px;
    border-top-color: #808080;
    border-top-style: solid;
    border-right-width: 0px;
    border-right-color: #808080;
    border-right-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #808080;
    border-bottom-style: solid;
    border-left-width: 0px;
    border-left-color: #808080;
    border-left-style: solid;
    font-size: 14px;
    color: white;
}

#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item>a,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item>a:hover {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    border-top-width: 0px;
    border-top-color: #808080;
    border-top-style: solid;
    border-right-width: 0px;
    border-right-color: #808080;
    border-right-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #808080;
    border-bottom-style: solid;
    border-left-width: 0px;
    border-left-color: #808080;
    border-left-style: solid;
    font-size: 14px;
    color: white;
}

#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>ul>li>a {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    border-top-width: 0px;
    border-top-color: #808080;
    border-top-style: solid;
    border-right-width: 0px;
    border-right-color: #808080;
    border-right-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #808080;
    border-bottom-style: solid;
    border-left-width: 0px;
    border-left-color: #808080;
    border-left-style: solid;
    font-size: 14px;
    color: white;
}

#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>ul>li.current_page_item>a,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>ul>li.current_page_item>a:hover {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    border-top-width: 0px;
    border-top-color: #808080;
    border-top-style: solid;
    border-right-width: 0px;
    border-right-color: #808080;
    border-right-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #808080;
    border-bottom-style: solid;
    border-left-width: 0px;
    border-left-color: #808080;
    border-left-style: solid;
    font-size: 14px;
    color: white;
}

#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>a>svg,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>a>.arrow-wrapper {
    padding-right: 20px;
    padding-left: 20px;
    color: black;
}

#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li.current_page_item>a>svg,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item:hover>a>svg,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li.current_page_item>a>.arrow-wrapper,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item:hover>a>.arrow-wrapper {
    padding-right: 20px;
    padding-left: 20px;
    color: black;
}

#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>ul li>a>svg,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>ul li>a>.arrow-wrapper {
    color: white;
    padding-right: 20px;
    padding-left: 20px;
}

#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>ul li.current_page_item>a>svg,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>ul>li.current_page_item:hover>a>svg,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>ul li.current_page_item>a>.arrow-wrapper,
#colibri .style-580>div>.colibri-menu-container>ul.colibri-menu li>ul>li.current_page_item:hover>a>.arrow-wrapper {
    color: white;
    padding-right: 20px;
    padding-left: 20px;
}

#colibri .style-582 {
    text-align: center;
    height: auto;
    min-height: unset;
}

#colibri .style-583-image {
    max-height: 70px;
}

#colibri .style-583 a,
#colibri .style-583 .logo-text {
    color: #ffffff;
    text-decoration: none;
}

#colibri .h-navigation_sticky .style-583-image,
#colibri .h-navigation_sticky.style-583-image {
    max-height: 70px;
}

#colibri .h-navigation_sticky .style-583 a,
#colibri .h-navigation_sticky .style-583 .logo-text,
#colibri .h-navigation_sticky.style-583 a,
#colibri .h-navigation_sticky.style-583 .logo-text {
    color: #000000;
    text-decoration: none;
}

#colibri .style-585 {
    text-align: center;
    height: auto;
    min-height: unset;
}

#colibri .style-586 ol {
    list-style-type: decimal;
}

#colibri .style-586 ul {
    list-style-type: disc;
}

#colibri .style-921 {
    height: auto;
    min-height: unset;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #303e7a;
    background-image: none;
}

#colibri .style-925 {
    text-align: left;
    height: auto;
    min-height: unset;
}

#colibri .style-928 {
    text-align: right;
}

#colibri .style-929-icon {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    margin-left: 0px;
}

#colibri .style-929 {
    padding-top: 12px;
    padding-right: 24px;
    padding-bottom: 12px;
    padding-left: 24px;
    font-family: Open Sans;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1em;
    letter-spacing: 1px;
    color: #fff;
    text-align: center;
    background-color: #f66161;
    background-image: none;
    border-top-width: 2px;
    border-top-color: #f66161;
    border-top-style: solid;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-right-width: 2px;
    border-right-color: #f66161;
    border-right-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #f66161;
    border-bottom-style: solid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-left-width: 2px;
    border-left-color: #f66161;
    border-left-style: solid;
}

#colibri .style-929:hover,
#colibri .style-929:focus {
    background-color: rgb(161, 63, 63);
}

#colibri .style-929:active .style-929-icon {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    margin-left: 0px;
}

#colibri .style-930-icon {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    margin-left: 0px;
}

#colibri .style-930 {
    text-align: center;
    background-color: #f6f8fa;
    background-image: none;
    font-family: Open Sans;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1px;
    color: #f66161;
    border-top-width: 2px;
    border-top-color: #303e7a;
    border-top-style: solid;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-right-width: 2px;
    border-right-color: #303e7a;
    border-right-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #303e7a;
    border-bottom-style: solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left-width: 2px;
    border-left-color: #303e7a;
    border-left-style: solid;
    padding-top: 12px;
    padding-right: 24px;
    padding-bottom: 12px;
    padding-left: 24px;
}

#colibri .style-930:hover,
#colibri .style-930:focus {
    background-color: #f66161;
    color: #f6f8fa;
    border-top-color: rgb(7, 29, 122);
    border-right-color: rgb(7, 29, 122);
    border-bottom-color: rgb(7, 29, 122);
    border-left-color: rgb(7, 29, 122);
}

#colibri .style-930:active .style-930-icon {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    margin-left: 0px;
}

#colibri .style-952 {
    text-align: center;
    height: auto;
    min-height: unset;
}

#colibri .style-954-icon {
    fill: #f6f8fa;
    width: 40px;
    height: 40px;
    border-top-width: 1px;
    border-top-color: rgb(125, 79, 79);
    border-top-style: none;
    border-right-width: 1px;
    border-right-color: rgb(125, 79, 79);
    border-right-style: none;
    border-bottom-width: 1px;
    border-bottom-color: rgb(125, 79, 79);
    border-bottom-style: none;
    border-left-width: 1px;
    border-left-color: rgb(125, 79, 79);
    border-left-style: none;
}

#colibri .style-954-icon:hover {
    fill: #f66161;
    background-color: rgba(45, 45, 134, 0);
    background-image: none;
}

#colibri .style-local-117-h27-outer {
    width: 100%;
    flex: 0 0 auto;
    -ms-flex: 0 0 auto;
}

#colibri .style-local-117-h12-outer {
    width: 100%;
    flex: 0 0 auto;
    -ms-flex: 0 0 auto;
}

#colibri .h-navigation_sticky .style-local-117-h12-outer,
#colibri .h-navigation_sticky.style-local-117-h12-outer {
    flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

#colibri .style-local-117-h14-outer {
    width: 100%;
    flex: 0 0 auto;
    -ms-flex: 0 0 auto;
}

#colibri .h-navigation_sticky .style-local-117-h14-outer,
#colibri .h-navigation_sticky.style-local-117-h14-outer {
    flex: 1 1 0;
    -ms-flex: 1 1 0%;
    max-width: 100%;
}

.style-717>.h-y-container>*:not(:last-child) {
    margin-bottom: 0px;
}

#colibri .style-local-122-f4-outer {
    width: 100%;
    flex: 0 0 auto;
    -ms-flex: 0 0 auto;
}

@media (max-width: 767px) {
    #colibri .style-576 {
        text-align: right;
    }

    #colibri .h-navigation_sticky .style-576,
    #colibri .h-navigation_sticky.style-576 {
        text-align: right;
    }

    #colibri .style-578-offscreen {
        background-color: #f66161;
    }

    #colibri .style-578 .h-hamburger-icon {
        background-color: #f7e597;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }

    #colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li>a {
        border-bottom-color: rgba(255, 255, 255, 0.3);
        text-transform: capitalize;
    }

    #colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li.hover>a {
        background-color: #303e7a;
        background-image: none;
    }

    #colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li:hover>a,
    #colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li.hover>a {
        background-color: #303e7a;
        background-image: none;
    }

    #colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item>a,
    #colibri .style-580>div>.colibri-menu-container>ul.colibri-menu>li.current_page_item>a:hover {
        border-bottom-color: rgba(255, 255, 255, 0.3);
        background-color: #303e7a;
        background-image: none;
        text-transform: capitalize;
    }

    #colibri .style-583 a,
    #colibri .style-583 .logo-text {
        text-transform: uppercase;
    }

    #colibri .style-583 .logo-text {
        color: #f6f8fa;
    }

    #colibri .style-586 {
        color: rgb(79, 165, 250);
    }

    #colibri .style-586 p {
        color: rgb(79, 165, 250);
    }

    #colibri .style-925 {
        text-align: center;
    }

    #colibri .style-local-117-h12-outer {
        width: auto;
        max-width: 100%;
    }

    #colibri .style-local-117-h14-outer {
        flex: 1 1 0;
        -ms-flex: 1 1 0%;
        max-width: 100%;
    }

    #colibri .style-local-117-h20-outer {
        width: 100%;
        flex: 0 0 auto;
        -ms-flex: 0 0 auto;
    }

    #colibri .style-local-117-h23-outer {
        width: 100%;
        flex: 0 0 auto;
        -ms-flex: 0 0 auto;
    }

    #colibri .style-local-117-h4-outer {
        width: 80%;
        flex: 0 0 auto;
        -ms-flex: 0 0 auto
    }

    #colibri .style-local-117-h8-outer {
        width: 20%;
        flex: 0 0 auto;
        -ms-flex: 0 0 auto;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {

    #colibri .h-navigation_sticky .style-577>div>.colibri-menu-container>ul.colibri-menu,
    #colibri .h-navigation_sticky.style-577>div>.colibri-menu-container>ul.colibri-menu {
        justify-content: center;
    }

    #colibri .h-navigation_sticky .style-local-117-h12-outer,
    #colibri .h-navigation_sticky.style-local-117-h12-outer {
        width: 100%;
    }

    #colibri .h-navigation_sticky .style-local-117-h14-outer,
    #colibri .h-navigation_sticky.style-local-117-h14-outer {
        width: 100%;
        flex: 0 0 auto;
        -ms-flex: 0 0 auto;
    }
}

.header-top {
    height: auto;
    min-height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #303e7a;
    background-image: none;
}

/* custom css */
.h-logo__text {
    color: #f66161;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1em;

}

.nav_menu{
    cursor: pointer;
}

.nav_menu.active {
    background-color: #303e7a;
    background-image: none;
    height: 3px;
    cursor: pointer;
}

.nav_menu.border-color {
    border-bottom: solid 5px #303e7a;
    /* border-bottom: #303e7a; */
}

/* custom css end */