* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.per-section {
    padding: 90px 0;
}

.hero-banner {
    height: auto;
    min-height: unset;
    color: #f6f8fa;
    background-position: 50.59844075985506% 0%;
    background-size: cover;

    background-image: url(https://jiliko.io/wp-content/uploads/2023/02/banner-bg.jpg);
    background-attachment: scroll;
    background-repeat: no-repeat;
    padding-top: 150px;
    padding-bottom: 350px;
    z-index: 0;

    background: linear-gradient(to top, #795bfa79, #a113ff83), url(https://jiliko.io/wp-content/uploads/2023/02/banner-bg.jpg) no-repeat top center;

}

.hero-banner {
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(63, 47, 47, 0.5);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
}

.text-title {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 900;
    font-size: 3.375em;
    line-height: 1.26;
    color: rgb(23, 37, 42);
    text-transform: uppercase;
}

.text-subtitle {
    font-family: Muli;
    font-weight: 300;
    font-size: 21px;
}

.best-bonuses {
    height: auto;
    min-height: unset;
    background-color: #202a54;
    padding: 90px 0px;

    background-position: center center;
    background-size: cover;
    background-image: url(https://jiliko.io/wp-content/uploads/2023/02/bg-1-1.jpg);
    background-attachment: scroll;
    background-repeat: no-repeat;
}

.bet-offers {
    height: auto;
    padding: 20px;
}

.title {
    /* width: 588px; */
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 700;
    font-size: 2.5em;
    line-height: 1.143;
    color: #f66161;
    text-transform: uppercase;
}

.title-sub {
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 300;
    font-size: 1.25em;
    line-height: 1.5;
    color: rgb(60, 65, 84);
}

.card-img-top.card-image {
    width: 100%;
    height: 250px;
    background-color: #f6f8fa;
    background-position: center center;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;

}

.btn-join-us {
    background-color: #f66161;
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    padding: 15px 30px;
}

/* join */
.join-section {
    height: auto;
    min-height: unset;
    background-color: #303e7a;
    background-image: none;
    color: #f6f8fa;

    padding: 90px 0px;


}

.image-join {

    right: 0;
    width: auto;
    height: 100% !important;
}

.join-text {
    text-align: left;
    height: auto;
    min-height: unset;
    /* padding-right: 6rem;
    padding-left: 8rem; */
}

.text-title-join {
    color: white;
}

/* join end */

/* download  */
.image-left {
    margin-left: -20px;
}

.section-title {
    text-align: left;
    height: auto;
    min-height: unset;
    padding-top: 5%;
    padding-right: 12%;
    padding-bottom: 5%;
    padding-left: 17px;
}

.download-btn {
    background-color: #f66161;
    border: 0;
}

/* download  ende */

/* enjoy section  */
.enjoy-section {
    height: auto;
    min-height: unset;
    background-position: center center;
    background-size: cover;
    background-image: url(https://jiliko.io/wp-content/uploads/2023/02/JokerSuperReels.jpg);
    background-attachment: scroll;
    background-repeat: no-repeat;
}

/* enjoy section end */

.nopage  {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}